import { DEEFAULT_ROUTER_PROFILE } from "routes/routes";
import { ECurrency } from "types/Task.type";

export const ICON = "/svg/logo/isotipo.svg";
export const LOGO_ISOTIPO = "/svg/logo/isotipo.svg";
export const LOGO_FONTS_DARK = "/svg/logo/logo-fonts-dark.svg";
export const LOGO_FONTS_LIGHT = "/svg/logo/logo-fonts-light.svg";
export const LOGO_DARK = "/images/logo/dark/HorizontalLogo.svg";
export const LOGO_LIGHT = "/images/logo/light/HorizontalLogo.svg";

export const VERTICAL_LOGO_DARK = "/images/logo/dark/VerticalLogo.svg";
export const VERTICAL_LOGO_LIGHT = "/images/logo/light/VerticalLogo.svg";

export const DEFAULT_COVER = "/images/svg/default-cover.svg";
export const NO_MEDIA_CARD_LIGHT = "/images/svg/no-media-light.svg";
export const NO_MEDIA_CARD_DARK = "/images/svg/no-media-dark.svg";

export const isBrowser = typeof window !== "undefined";

export const USER_TOKEN_PERSIST =
  "x1QTUA0Pe8Sat2AGdsZ31f8HYOxYlR90Wfk0yDcX17pSs";
export const REFRESH_TOKEN_PERSIST =
  "x1QTUA0Pe8Sat2AGdsZ31f8HYOxYlR90Wfk0yDcX17pSe";
export const SCOPE = "x1QTUA0Pe8Sat2AGdsZ31f8HYOxYlR90Wfk0yDcX17pSf";
export const APPLICANT_TOKEN_PERSIST =
  "x1QTUA0Pe8Sat2AGdsZ31f8HYOxYlR90Wfk0yDcX17pSg";
export const USER_LANG = "x1QTUA0Pe8Sat2AGdsZ31f8HYOxYlR90Wfk0yDcX17gGg";
export const SELECTED_VENDOR = "x1QTUA0Pe8Sat2AGdsZ31f8HYOxYlR90Wfk0yDcX17gGv";
export const HAS_USER_OPENED_QUICKIES =
  "x1QTUA0Pe8Sat2AGdsZ31f8HYOxYlR90Wfk0yDcX17h52";
export const INVITE_CODE = "x1QTUA0Pe8Sat2AGdsZ31f8HYOxYlR90Wfk0yDcX135a2";
export const DIALOG_TEXTS_NO_SHOW_AGAIN =
  "x1QTUA0Pe8Sat2AGdsZ31f8HYOxYlR90Wfk0yDcX1355ws";

export const HAS_USER_OPENED_AIQUICKIES =
  "x1QTUA0Pe8Sat2AGdsZ31f8HYOxYlR90Wfk0yDcX135a3";

export const HAS_USER_OPENED_CV_AI_HELPER =
  "x1QTUA0Pe8Sat2AGdsZ31f8HYOxYlR90Wfk0yDcX135a4";

export const RSUITE_SM = 480;
export const RSUITE_MD = 992;
export const RSUITE_LG = 1200;
export const MOBILE_VIEW = 1024;
export const SIDEBAR_WIDTH = 300;

export const AFTER_LOGIN_REDIRECT = DEEFAULT_ROUTER_PROFILE;
export const AFTER_REDIRECT_ONBOARDING = "/app/onboarding";

export enum GraphQLErrors {
  UNAUTHENTICATED = "UNAUTHENTICATED",
  BAD_USER_INPUT = "BAD_USER_INPUT",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  GRAPHQL_VALIDATION_FAILED = "GRAPHQL_VALIDATION_FAILED",
  GRAPHQL_PARSE_FAILED = "GRAPHQL_PARSE_FAILED",
}

export enum ESystemRoles {
  "USER" = "USER",
  "ADMIN" = "ADMIN",
  "LEADER" = "LEADER",
}

export enum EAssignStatus {
  "ASSIGNED" = "ASSIGNED",
  "ASSIGNABLE_NOT_ASSIGNED" = "ASSIGNABLE_NOT_ASSIGNED",
  "NOT_ASSIGNABLE" = "NOT_ASSIGNABLE",
  "NOT_ASSIGNED" = "NOT_ASSIGNED",
}

export const THEME = {
  light: "light",
  dark: "dark",
};

export const NO_ORGANIZATION_REDIRECT = "/no-organization";

export const LOGO_FACEBOOK = "/images/icon/facebook.svg";
export const LOGO_GOOGLE = "/images/icon/google.svg";
export const LOGO_LINKEDIN = "/images/svg/linkedin.svg";

export const POWERED_BY_NODRIZE = "/images/svg/powerby-nodrize-white.svg";

export const LOGO_GROOWCITY = "/images/illustrations/groowcity.svg";

export const NEW_ORGANIZATION = "/images/illustrations/new-organization.svg";

export const ILLUSTRATION_LOGIN = "/images/illustrations/login.svg";
export const REGISTRATION_ILLUSTRATION =
  "/images/illustrations/new-register.svg";

export const NEW_RECOVER = "/images/illustrations/new-recover.svg";
export const ILLUSTRATION_REGISTER = "/images/illustrations/register.svg";
export const ILLUSTRATION_RECOVERY = "/images/illustrations/recovery.svg";
export const ILLUSTRATION_STICKERS_1 = "/images/illustrations/stickers_1.svg";
export const ILLUSTRATION_COFFE_CUP = "/images/illustrations/coffee_cup.svg";
export const ILLUSTRATION_SURVEY = "/images/illustrations/survey.svg";
export const RECOVERY_PASSWORD_WITH_TOKEN = "recovery-password-send";
export const DEFAULT_BANNER = "/images/illustrations/default-banner.svg";
export const NO_MAGAZINE = "/images/illustrations/no-magazine.svg";
export const NO_EVENTS = "/images/illustrations/no-events.svg";

export const TYPE_ANSWER_QUESTION = [
  "Respuesta Abierta",
  "Selección simple, respuesta correcta e incorrecta",
];

export const PRIMARY_COLOR = "#3F33FF"; // dark mode props color
export const SECONDARY_COLOR = "#3498FF"; // light mode props color
export const DANGER_COLOR = "#d33";
export const WARNING_COLOR = "#FFC107";
export const SWAL_BG_LIGHT_COLOR = "#F9FAFB";
export const SWAL_BG_DARK_COLOR = "#101319";
export const SWAL_FONT_LIGHT_COLOR = "#1E293B";
export const SWAL_FONT_DARK_COLOR = "#F9FAFB";

export const ALLOWED_EXTENSIONS =
  ".pdf,.doc,.docx,.xls,.ppt,.pptx,.xlsx,.jpeg,.jpg,.gif,.mp4,.avi,.svg,.webp";

export const ALLOWED_THUMBNAIL_EXTENSIONS = ".png,.jpg,.jpeg,.gif,.svg,.webp";
export const ALLOWED_THUMBNAIL_EXTENSIONS_CORPORATE_VALUES =
  ".png,.jpg,.jpeg,.svg,.webp";

export const MAX_FILE_SIZE = 50242880; // * 50MB
export const MAX_FILE_SIZE_MULTIMEDIA = 1073741824; // * 1 gigabyte
export const MAX_FILE_THUMBNAIL_SIZE = 1024; // * 1MB

export const DEFAULT_ROLE_AVATAR = "/images/jobs/base.png";

export const DEFAULT_AVATAR = "some-default-url-avatar-for-users";
export const BORING_DEFAULT_AVATAR =
  "https://source.boringavatars.com/beam/120/Maria%20Mitchell?colors=264653,2a9d8f,e9c46a,f4a261,e76f51";

export const WIDTH_SM = 640;
export const WIDTH_MD = 769;
export const WIDTH_LG = 1024;
export const WIDTH_XL = 1280;
export const WIDTH_2XL = 1536;
export const WIDTH_EXPLORE = 1200;

export const AVATAR_COLORS = [
  "#92A1C6",
  "#146A7C",
  "#F0AB3D",
  "#C271B4",
  "#C20D90",
];

export const PASSWORD_REGEX = /(?=^.{8,}$)(?=.*\d)(?![.\n])(?=.*[A-Z]).*$/;
export const NUMBER_SPACES_AND_LETTER_REGEX =
  /^[A-Za-z0-9\u00C0-\u017F.\-\s]+$/;
export const ONLY_WORDS_SPACES_DASH_DOT_REGEX = /^[A-Za-z\u00C0-\u017F.\-\s]+$/;
export const ONLY_WORDS_AND_SPACES_REGEX = /^[A-zÀ-ú\s]*$/;
export const NAME_VALIDATION_REGEX =
  /^[A-zÀ-ú]+[[A-zÀ-ú0-9 ]*[A-zÀ-ú0-9][A-zÀ-ú0-9 ]*/;

// * only letters and - without spaces and diacritics
export const ONLY_LETTERS_AND_DASH_REGEX = /^[A-z-]*$/;

export const ANY_CHARACTER_REGEX = /^[A-zÀ-ú\s]*$/;

export const VALID_URL_REGEX =
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;

export const ALPHABET = [
  "Q",
  "W",
  "E",
  "R",
  "T",
  "Y",
  "U",
  "I",
  "O",
  "P",
  "A",
  "S",
  "D",
  "F",
  "G",
  "H",
  "J",
  "K",
  "L",
  "Z",
  "X",
  "C",
  "V",
  "B",
  "N",
  "M",
];

export const NUMERICAL_OPTIONS = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
];

interface ICurrencies {
  label: ECurrency;
  value: ECurrency;
}

export const CURRENCIES: ICurrencies[] = [
  { label: ECurrency.CLP, value: ECurrency.CLP },
  {
    label: ECurrency.USD,
    value: ECurrency.USD,
  },
  { label: ECurrency.EUR, value: ECurrency.EUR },
];

// * https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
export const COUNTRIES = [
  { label: "LATAM", value: "LATAM" },
  { label: "Afghanistan", value: "AF" },
  { label: "Aland Islands", value: "AX" },
  { label: "Albania", value: "AL" },
  { label: "Algeria", value: "DZ" },
  { label: "American Samoa", value: "AS" },
  { label: "Andorra", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Anguilla", value: "AI" },
  { label: "Antarctica", value: "AQ" },
  { label: "Antigua and Barbuda", value: "AG" },
  { label: "Argentina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Azerbaijan", value: "AZ" },
  { label: "Bahamas", value: "BS" },
  { label: "Bahrain", value: "BH" },
  { label: "Bangladesh", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Belarus", value: "BY" },
  { label: "Belgium", value: "BE" },
  { label: "Belize", value: "BZ" },
  { label: "Benin", value: "BJ" },
  { label: "Bermuda", value: "BM" },
  { label: "Bhutan", value: "BT" },
  { label: "Bolivia", value: "BO" },
  { label: "Bonaire, Sint Eustatius and Saba", value: "BQ" },
  { label: "Bosnia and Herzegovina", value: "BA" },
  { label: "Botswana", value: "BW" },
  { label: "Bouvet Island", value: "BV" },
  { label: "Brazil", value: "BR" },
  { label: "British Indian Ocean Territory", value: "IO" },
  { label: "Brunei Darussalam", value: "BN" },
  { label: "Bulgaria", value: "BG" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Cabo Verde", value: "CV" },
  { label: "Cambodia", value: "KH" },
  { label: "Cameroon", value: "CM" },
  { label: "Canada", value: "CA" },
  { label: "Cayman Islands", value: "KY" },
  { label: "Central African Republic", value: "CF" },
  { label: "Chad", value: "TD" },
  { label: "Chile", value: "CL" },
  { label: "China", value: "CN" },
  { label: "Christmas Island", value: "CX" },
  { label: "Cocos (Keeling) Islands", value: "CC" },
  { label: "Colombia", value: "CO" },
  { label: "Comoros", value: "KM" },
  { label: "Congo", value: "CG" },
  { label: "Congo, Democratic Republic of the", value: "CD" },
  { label: "Cook Islands", value: "CK" },
  { label: "Costa Rica", value: "CR" },
  { label: "Côte d'Ivoire", value: "CI" },
  { label: "Croatia", value: "HR" },
  { label: "Cuba", value: "CU" },
  { label: "Curaçao", value: "CW" },
  { label: "Cyprus", value: "CY" },
  { label: "Czechia", value: "CZ" },
  { label: "Denmark", value: "DK" },
  { label: "Djibouti", value: "DJ" },
  { label: "Dominica", value: "DM" },
  { label: "Dominican Republic", value: "DO" },
  { label: "Ecuador", value: "EC" },
  { label: "Egypt", value: "EG" },
  { label: "El Salvador", value: "SV" },
  { label: "Equatorial Guinea", value: "GQ" },
  { label: "Eritrea", value: "ER" },
  { label: "Estonia", value: "EE" },
  { label: "Eswatini", value: "SZ" },
  { label: "Ethiopia", value: "ET" },
  { label: "Falkland Islands (Malvinas)", value: "FK" },
  { label: "Faroe Islands", value: "FO" },
  { label: "Fiji", value: "FJ" },
  { label: "Finland", value: "FI" },
  { label: "France", value: "FR" },
  { label: "French Guiana", value: "GF" },
  { label: "French Polynesia", value: "PF" },
  { label: "French Southern Territories", value: "TF" },
  { label: "Gabon", value: "GA" },
  { label: "Gambia", value: "GM" },
  { label: "Georgia", value: "GE" },
  { label: "Germany", value: "DE" },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Greece", value: "GR" },
  { label: "Greenland", value: "GL" },
  { label: "Grenada", value: "GD" },
  { label: "Guadeloupe", value: "GP" },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guernsey", value: "GG" },
  { label: "Guinea", value: "GN" },
  { label: "Guinea-Bissau", value: "GW" },
  { label: "Guyana", value: "GY" },
  { label: "Haiti", value: "HT" },
  { label: "Heard Island and McDonald Islands", value: "HM" },
  { label: "Holy See", value: "VA" },
  { label: "Honduras", value: "HN" },
  { label: "Hong Kong", value: "HK" },
  { label: "Hungary", value: "HU" },
  { label: "Iceland", value: "IS" },
  { label: "India", value: "IN" },
  { label: "Indonesia", value: "ID" },
  { label: "Iran, Islamic Republic of", value: "IR" },
  { label: "Iraq", value: "IQ" },
  { label: "Ireland", value: "IE" },
  { label: "Isle of Man", value: "IM" },
  { label: "Israel", value: "IL" },
  { label: "Italy", value: "IT" },
  { label: "Jamaica", value: "JM" },
  { label: "Japan", value: "JP" },
  { label: "Jersey", value: "JE" },
  { label: "Jordan", value: "JO" },
  { label: "Kazakhstan", value: "KZ" },
  { label: "Kenya", value: "KE" },
  { label: "Kiribati", value: "KI" },
  { label: "Korea, Democratic People's Republic of", value: "KP" },
  { label: "Korea, Republic of", value: "KR" },
  { label: "Kuwait", value: "KW" },
  { label: "Kyrgyzstan", value: "KG" },
  { label: "Lao People's Democratic Republic", value: "LA" },
  { label: "Latvia", value: "LV" },
  { label: "Lebanon", value: "LB" },
  { label: "Lesotho", value: "LS" },
  { label: "Liberia", value: "LR" },
  { label: "Libya", value: "LY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lithuania", value: "LT" },
  { label: "Luxembourg", value: "LU" },
  { label: "Madagascar", value: "MG" },
  { label: "Malawi", value: "MW" },
  { label: "Malaysia", value: "MY" },
  { label: "Maldives", value: "MV" },
  { label: "Mali", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Marshall Islands", value: "MH" },
  { label: "Mauritania", value: "MR" },
  { label: "Mauritius", value: "MU" },
  { label: "Mayotte", value: "YT" },
  { label: "Mexico", value: "MX" },
  { label: "Micronesia (Federated States of)", value: "FM" },
  { label: "Moldova, Republic of", value: "MD" },
  { label: "Monaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montenegro", value: "ME" },
  { label: "Montserrat", value: "MS" },
  { label: "Morocco", value: "MA" },
  { label: "Mozambique", value: "MZ" },
  { label: "Myanmar", value: "MM" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Netherlands", value: "NL" },
  { label: "New Caledonia", value: "NC" },
  { label: "New Zealand", value: "NZ" },
  { label: "Nicaragua", value: "NI" },
  { label: "Niger", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU" },
  { label: "Norfolk Island", value: "NF" },
  { label: "North Macedonia", value: "MK" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Norway", value: "NO" },
  { label: "Oman", value: "OM" },
  { label: "Pakistan", value: "PK" },
  { label: "Palau", value: "PW" },
  { label: "Palestine, State of", value: "PS" },
  { label: "Panama", value: "PA" },
  { label: "Papua New Guinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Peru", value: "PE" },
  { label: "Philippines", value: "PH" },
  { label: "Pitcairn", value: "PN" },
  { label: "Poland", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Qatar", value: "QA" },
  { label: "Réunion", value: "RE" },
  { label: "Romania", value: "RO" },
  { label: "Russian Federation", value: "RU" },
  { label: "Rwanda", value: "RW" },
  { label: "Saint Barthélemy", value: "BL" },
  { label: "Saint Helena, Ascension and Tristan da Cunha", value: "SH" },
  { label: "Saint Kitts and Nevis", value: "KN" },
  { label: "Saint Lucia", value: "LC" },
  { label: "Saint Martin (French part)", value: "MF" },
  { label: "Saint Pierre and Miquelon", value: "PM" },
  { label: "Saint Vincent and the Grenadines", value: "VC" },
  { label: "Samoa", value: "WS" },
  { label: "San Marino", value: "SM" },
  { label: "Sao Tome and Principe", value: "ST" },
  { label: "Saudi Arabia", value: "SA" },
  { label: "Senegal", value: "SN" },
  { label: "Serbia", value: "RS" },
  { label: "Seychelles", value: "SC" },
  { label: "Sierra Leone", value: "SL" },
  { label: "Singapore", value: "SG" },
  { label: "Sint Maarten (Dutch part)", value: "SX" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
  { label: "Solomon Islands", value: "SB" },
  { label: "Somalia", value: "SO" },
  { label: "South Africa", value: "ZA" },
  { label: "South Georgia and the South Sandwich Islands", value: "GS" },
  { label: "South Sudan", value: "SS" },
  { label: "Spain", value: "ES" },
  { label: "Sri Lanka", value: "LK" },
  { label: "Sudan", value: "SD" },
  { label: "Suriname", value: "SR" },
  { label: "Svalbard and Jan Mayen", value: "SJ" },
  { label: "Sweden", value: "SE" },
  { label: "Switzerland", value: "CH" },
  { label: "Syrian Arab Republic", value: "SY" },
  { label: "Taiwan, Province of China", value: "TW" },
  { label: "Tajikistan", value: "TJ" },
  { label: "Tanzania, United Republic of", value: "TZ" },
  { label: "Thailand", value: "TH" },
  { label: "Timor-Leste", value: "TL" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad and Tobago", value: "TT" },
  { label: "Tunisia", value: "TN" },
  { label: "Turkey", value: "TR" },
  { label: "Turkmenistan", value: "TM" },
  { label: "Tuvalu", value: "TV" },
  { label: "Uganda", value: "UG" },
  { label: "Ukraine", value: "UA" },
  { label: "United Arab Emirates", value: "AE" },
  {
    label: "United Kingdom of Great Britain and Northern Ireland",
    value: "GB",
  },
  { label: "United States of America", value: "US" },
  { label: "Uruguay", value: "UY" },
  { label: "Uzbekistan", value: "UZ" },
  { label: "Vanuatu", value: "VU" },
  { label: "Venezuela (Bolivarian Republic of)", value: "VE" },
  { label: "Viet Nam", value: "VN" },
  { label: "Western Sahara", value: "EH" },
  { label: "Yemen", value: "YE" },
  { label: "Zambia", value: "ZM" },
  { label: "Zimbabwe", value: "ZW" },
];

export const COUNTRIES_ES = [
  { label: "Latinoamérica", value: "LATAM" },
  { label: "Afganistán", value: "AF" },
  { label: "Islas Aland", value: "AX" },
  { label: "Albania", value: "AL" },
  { label: "Argelia", value: "DZ" },
  { label: "Samoa Americana", value: "AS" },
  { label: "Andorra", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Anguila", value: "AI" },
  { label: "Antártida", value: "AQ" },
  { label: "Antigua y Barbuda", value: "AG" },
  { label: "Argentina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Azerbaiyán", value: "AZ" },
  { label: "Bahamas", value: "BS" },
  { label: "Baréin", value: "BH" },
  { label: "Bangladés", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Bielorrusia", value: "BY" },
  { label: "Bélgica", value: "BE" },
  { label: "Belice", value: "BZ" },
  { label: "Benín", value: "BJ" },
  { label: "Bermudas", value: "BM" },
  { label: "Bután", value: "BT" },
  { label: "Bolivia", value: "BO" },
  { label: "Bonaire, San Eustaquio y Saba", value: "BQ" },
  { label: "Bosnia y Herzegovina", value: "BA" },
  { label: "Botsuana", value: "BW" },
  { label: "Isla Bouvet", value: "BV" },
  { label: "Brasil", value: "BR" },
  { label: "Territorio Británico del Océano Índico", value: "IO" },
  { label: "Brunéi Darusalam", value: "BN" },
  { label: "Bulgaria", value: "BG" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Cabo Verde", value: "CV" },
  { label: "Camboya", value: "KH" },
  { label: "Camerún", value: "CM" },
  { label: "Canadá", value: "CA" },
  { label: "Islas Caimán", value: "KY" },
  { label: "República Centroafricana", value: "CF" },
  { label: "Chad", value: "TD" },
  { label: "Chile", value: "CL" },
  { label: "China", value: "CN" },
  { label: "Isla de Navidad", value: "CX" },
  { label: "Islas Cocos (Keeling)", value: "CC" },
  { label: "Colombia", value: "CO" },
  { label: "Comoras", value: "KM" },
  { label: "Congo", value: "CG" },
  { label: "República Democrática del Congo", value: "CD" },
  { label: "Islas Cook", value: "CK" },
  { label: "Costa Rica", value: "CR" },
  { label: "Costa de Marfil", value: "CI" },
  { label: "Croacia", value: "HR" },
  { label: "Cuba", value: "CU" },
  { label: "Curazao", value: "CW" },
  { label: "Chipre", value: "CY" },
  { label: "Chequia", value: "CZ" },
  { label: "Dinamarca", value: "DK" },
  { label: "Yibuti", value: "DJ" },
  { label: "Dominica", value: "DM" },
  { label: "República Dominicana", value: "DO" },
  { label: "Ecuador", value: "EC" },
  { label: "Egipto", value: "EG" },
  { label: "El Salvador", value: "SV" },
  { label: "Guinea Ecuatorial", value: "GQ" },
  { label: "Eritrea", value: "ER" },
  { label: "Estonia", value: "EE" },
  { label: "Esuatini", value: "SZ" },
  { label: "Etiopía", value: "ET" },
  { label: "Islas Malvinas (Falkland)", value: "FK" },
  { label: "Islas Feroe", value: "FO" },
  { label: "Fiyi", value: "FJ" },
  { label: "Finlandia", value: "FI" },
  { label: "Francia", value: "FR" },
  { label: "Guayana Francesa", value: "GF" },
  { label: "Polinesia Francesa", value: "PF" },
  { label: "Territorios Australes Franceses", value: "TF" },
  { label: "Gabón", value: "GA" },
  { label: "Gambia", value: "GM" },
  { label: "Georgia", value: "GE" },
  { label: "Alemania", value: "DE" },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Grecia", value: "GR" },
  { label: "Groenlandia", value: "GL" },
  { label: "Granada", value: "GD" },
  { label: "Guadalupe", value: "GP" },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guernesey", value: "GG" },
  { label: "Guinea", value: "GN" },
  { label: "Guinea-Bisáu", value: "GW" },
  { label: "Guyana", value: "GY" },
  { label: "Haití", value: "HT" },
  { label: "Islas Heard y McDonald", value: "HM" },
  { label: "Santa Sede", value: "VA" },
  { label: "Honduras", value: "HN" },
  { label: "Hong Kong", value: "HK" },
  { label: "Hungría", value: "HU" },
  { label: "Islandia", value: "IS" },
  { label: "India", value: "IN" },
  { label: "Indonesia", value: "ID" },
  { label: "Irán", value: "IR" },
  { label: "Irak", value: "IQ" },
  { label: "Irlanda", value: "IE" },
  { label: "Isla de Man", value: "IM" },
  { label: "Israel", value: "IL" },
  { label: "Italia", value: "IT" },
  { label: "Jamaica", value: "JM" },
  { label: "Japón", value: "JP" },
  { label: "Jersey", value: "JE" },
  { label: "Jordania", value: "JO" },
  { label: "Kazajistán", value: "KZ" },
  { label: "Kenia", value: "KE" },
  { label: "Kiribati", value: "KI" },
  { label: "Corea del Norte", value: "KP" },
  { label: "Corea del Sur", value: "KR" },
  { label: "Kuwait", value: "KW" },
  { label: "Kirguistán", value: "KG" },
  { label: "Laos", value: "LA" },
  { label: "Letonia", value: "LV" },
  { label: "Líbano", value: "LB" },
  { label: "Lesoto", value: "LS" },
  { label: "Liberia", value: "LR" },
  { label: "Libia", value: "LY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lituania", value: "LT" },
  { label: "Luxemburgo", value: "LU" },
  { label: "Madagascar", value: "MG" },
  { label: "Malaui", value: "MW" },
  { label: "Malasia", value: "MY" },
  { label: "Maldivas", value: "MV" },
  { label: "Malí", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Islas Marshall", value: "MH" },
  { label: "Martinica", value: "MQ" },
  { label: "Mauritania", value: "MR" },
  { label: "Mauricio", value: "MU" },
  { label: "Mayotte", value: "YT" },
  { label: "México", value: "MX" },
  { label: "Micronesia", value: "FM" },
  { label: "Moldavia", value: "MD" },
  { label: "Mónaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montenegro", value: "ME" },
  { label: "Montserrat", value: "MS" },
  { label: "Marruecos", value: "MA" },
  { label: "Mozambique", value: "MZ" },
  { label: "Birmania (Myanmar)", value: "MM" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Países Bajos", value: "NL" },
  { label: "Nueva Caledonia", value: "NC" },
  { label: "Nueva Zelanda", value: "NZ" },
  { label: "Nicaragua", value: "NI" },
  { label: "Níger", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU" },
  { label: "Isla Norfolk", value: "NF" },
  { label: "Macedonia del Norte", value: "MK" },
  { label: "Islas Marianas del Norte", value: "MP" },
  { label: "Noruega", value: "NO" },
  { label: "Omán", value: "OM" },
  { label: "Pakistán", value: "PK" },
  { label: "Palaos", value: "PW" },
  { label: "Territorio Palestino", value: "PS" },
  { label: "Panamá", value: "PA" },
  { label: "Papúa Nueva Guinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Perú", value: "PE" },
  { label: "Filipinas", value: "PH" },
  { label: "Pitcairn", value: "PN" },
  { label: "Polonia", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Catar", value: "QA" },
  { label: "Reunión", value: "RE" },
  { label: "Rumania", value: "RO" },
  { label: "Rusia", value: "RU" },
  { label: "Ruanda", value: "RW" },
  { label: "San Bartolomé", value: "BL" },
  { label: "Santa Elena, Ascensión y Tristán de Acuña", value: "SH" },
  { label: "San Cristóbal y Nieves", value: "KN" },
  { label: "Santa Lucía", value: "LC" },
  { label: "San Martín (Francia)", value: "MF" },
  { label: "San Pedro y Miquelón", value: "PM" },
  { label: "San Vicente y las Granadinas", value: "VC" },
  { label: "Samoa", value: "WS" },
  { label: "San Marino", value: "SM" },
  { label: "Santo Tomé y Príncipe", value: "ST" },
  { label: "Arabia Saudita", value: "SA" },
  { label: "Senegal", value: "SN" },
  { label: "Serbia", value: "RS" },
  { label: "Seychelles", value: "SC" },
  { label: "Sierra Leona", value: "SL" },
  { label: "Singapur", value: "SG" },
  { label: "Sint Maarten (Países Bajos)", value: "SX" },
  { label: "Eslovaquia", value: "SK" },
  { label: "Eslovenia", value: "SI" },
  { label: "Islas Salomón", value: "SB" },
  { label: "Somalia", value: "SO" },
  { label: "Sudáfrica", value: "ZA" },
  { label: "Islas Georgias del Sur y Sandwich del Sur", value: "GS" },
  { label: "Sudán del Sur", value: "SS" },
  { label: "España", value: "ES" },
  { label: "Sri Lanka", value: "LK" },
  { label: "Sudán", value: "SD" },
  { label: "Surinam", value: "SR" },
  { label: "Svalbard y Jan Mayen", value: "SJ" },
  { label: "Suecia", value: "SE" },
  { label: "Suiza", value: "CH" },
  { label: "Siria", value: "SY" },
  { label: "Taiwán", value: "TW" },
  { label: "Tayikistán", value: "TJ" },
  { label: "Tanzania", value: "TZ" },
  { label: "Tailandia", value: "TH" },
  { label: "Timor-Leste", value: "TL" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad y Tobago", value: "TT" },
  { label: "Túnez", value: "TN" },
  { label: "Turquía", value: "TR" },
  { label: "Turkmenistán", value: "TM" },
  { label: "Islas Turcas y Caicos", value: "TC" },
  { label: "Tuvalu", value: "TV" },
  { label: "Uganda", value: "UG" },
  { label: "Ucrania", value: "UA" },
  { label: "Emiratos Árabes Unidos", value: "AE" },
  { label: "Reino Unido", value: "GB" },
  { label: "Estados Unidos", value: "US" },
  { label: "Uruguay", value: "UY" },
  { label: "Uzbekistán", value: "UZ" },
  { label: "Vanuatu", value: "VU" },
  { label: "Venezuela", value: "VE" },
  { label: "Vietnam", value: "VN" },
  { label: "Wallis y Futuna", value: "WF" },
  { label: "Sahara Occidental", value: "EH" },
  { label: "Yemen", value: "YE" },
  { label: "Zambia", value: "ZM" },
  { label: "Zimbabue", value: "ZW" },
];

export const CATEGORIES = [
  { label: "Techniques", value: "Techniques" },
  { label: "Tools", value: "Tools" },
  { label: "Languages & Frameworks", value: "Languages & Frameworks" },
  { label: "Platforms", value: "Platforms" },
  { label: "Soft Skill", value: "Soft Skill" },
  { label: "None", value: "None" },
];

export const BASKET_MOCK = [
  {
    id: "abb3f336-a6c3-46f3-b517-ec69d0c7cbfc",
    name: "Polera de NestJS",
    imgUrl:
      "https://cdn.ndrz.io/b772df84d7a3ae30dafe651c9d714000/2d68a3bb-116b-47a3-8dc6-45a88c9f531e/products/546baee2-7d3e-4411-bffc-b5a87ccf4c6f.png",
    category: { name: "Geekz", id: "ba640c58-1bce-46f4-b7e2-d27133b704bc" },
    price: 50,
    deleted: false,
    active: true,
    description: "Una polera con el logo de NEXT JS Color Negro ",
    quantity: 10,
    newProduct: null,
    featured: null,
    epic: null,
  },
  {
    id: "cd3e9ba0-3ccc-4628-86de-8c5f40abf271",
    name: "Nintendo GBA",
    imgUrl:
      "https://cdn.ndrz.io/b772df84d7a3ae30dafe651c9d714000/2d68a3bb-116b-47a3-8dc6-45a88c9f531e/products/b49b0c0c-2b91-452b-9e51-918bd41e6d41.png",
    category: { name: "Geekz", id: "ba640c58-1bce-46f4-b7e2-d27133b704bc" },
    price: 300,
    deleted: false,
    active: true,
    description:
      "Una consola clásica de Game Boy Advance de Plush and Bits con 250 juegos instalados.",
    quantity: 2,
    newProduct: null,
    featured: null,
    epic: null,
  },
  {
    id: "85d2ac6c-0c13-4eef-a48c-524d963a9706",
    name: "Vinilo Music Jungle",
    imgUrl:
      "https://cdn.ndrz.io/b772df84d7a3ae30dafe651c9d714000/2d68a3bb-116b-47a3-8dc6-45a88c9f531e/products/fa61381c-b784-4f4c-bb27-d2e984fb0db5.png",
    category: { name: "Music", id: "af1b0896-b5cf-4989-a3dd-0790ce855d6b" },
    price: 150,
    deleted: false,
    active: true,
    description: null,
    quantity: null,
    newProduct: null,
    featured: null,
    epic: null,
  },
  {
    id: "e4722dc5-d874-4ad0-a30e-da5129592197",
    name: "Airpods",
    imgUrl:
      "https://cdn.ndrz.io/b772df84d7a3ae30dafe651c9d714000/2d68a3bb-116b-47a3-8dc6-45a88c9f531e/products/9e7a182d-42f5-4463-926d-1c4c95815053.png",
    category: {
      name: "Tecnología",
      id: "57dde28d-d273-4481-97db-4683797dfb78",
    },
    price: 350,
    deleted: false,
    active: true,
    description: "Clasicos Airpod de Apple",
    quantity: 2,
    newProduct: null,
    featured: null,
    epic: null,
  },
  {
    id: "4e57870d-fd8f-4484-9e81-962bea96ee13",
    name: "Viaje All Inclusive (Prueba)",
    imgUrl:
      "https://cdn.ndrz.io/b772df84d7a3ae30dafe651c9d714000/2d68a3bb-116b-47a3-8dc6-45a88c9f531e/products/59134b2c-498f-4d33-88b3-f76fd9c82859.png",
    category: {
      name: "Experiencias",
      id: "d0d9b8a5-d592-40d4-bb44-19253da5847a",
    },
    price: 2000,
    deleted: false,
    active: true,
    description: null,
    quantity: null,
    newProduct: null,
    featured: null,
    epic: null,
  },
  {
    id: "2f69e0cc-0d7b-46bf-a8b3-bc39c010bb63",
    name: "Prueba1",
    imgUrl:
      "https://cdn.ndrz.io/b772df84d7a3ae30dafe651c9d714000/2d68a3bb-116b-47a3-8dc6-45a88c9f531e/products/bec8c41a-9e28-4057-906e-acb39533fe99.jpeg",
    category: { name: "Music", id: "af1b0896-b5cf-4989-a3dd-0790ce855d6b" },
    price: 10,
    deleted: false,
    active: true,
    description: "dsrftrt",
    quantity: 6,
    newProduct: null,
    featured: null,
    epic: null,
  },
  {
    id: "26ae9fd3-5803-4598-8067-da25ada678b4",
    name: "Prueba2",
    imgUrl:
      "https://cdn.ndrz.io/b772df84d7a3ae30dafe651c9d714000/2d68a3bb-116b-47a3-8dc6-45a88c9f531e/products/a07a4111-28c8-4e3c-99f5-45fc24843948.jpeg",
    category: { name: "Music", id: "af1b0896-b5cf-4989-a3dd-0790ce855d6b" },
    price: 10,
    deleted: false,
    active: true,
    description: "drec",
    quantity: 2,
    newProduct: null,
    featured: null,
    epic: null,
  },
  {
    id: "7c651e72-3980-4dee-9601-692daf428a8d",
    name: "Prueba3",
    imgUrl:
      "https://cdn.ndrz.io/b772df84d7a3ae30dafe651c9d714000/2d68a3bb-116b-47a3-8dc6-45a88c9f531e/products/5fc104ec-b700-417d-ada4-6ca1e428cfc9.jpeg",
    category: { name: "Misc", id: "335518ec-38e5-4778-9a05-53adf9e6fddd" },
    price: 5,
    deleted: false,
    active: true,
    description: "",
    quantity: 1,
    newProduct: null,
    featured: null,
    epic: null,
  },
  {
    id: "7160c06d-55bc-43ed-89fa-5eea2250a641",
    name: "Prueba",
    imgUrl:
      "https://cdn.ndrz.io/b772df84d7a3ae30dafe651c9d714000/2d68a3bb-116b-47a3-8dc6-45a88c9f531e/products/c077aac6-8575-4312-a483-7a2a61906923.jpeg",
    category: { name: "Geekz", id: "ba640c58-1bce-46f4-b7e2-d27133b704bc" },
    price: 50,
    deleted: false,
    active: true,
    description: "frfr",
    quantity: 5,
    newProduct: null,
    featured: null,
    epic: null,
  },
];

export const TRANSACTION_REQUESTS_MOCK = [
  {
    data: {
      basket: [
        {
          id: "23cd76a3-6f51-41fa-a0fd-44987cb5a470",
          user: "ae579322-23ac-4878-9e8a-367e1afaeb02",
          basketMemory: [
            {
              product: {
                id: "d72b9b00-9add-4e5e-92bc-32d1c1afb423",
                name: "Polera de NEST JS",
                price: 50,
                category: {
                  id: "0f9300ef-17f7-407d-a9c1-073c1292970f",
                  name: "Misc",
                },
                epic: false,
                featured: false,
                newProduct: true,
                imgUrl:
                  "https://cdn.ndrz.io/b772df84d7a3ae30dafe651c9d714000/e9e0ff2a-7b75-4dc0-9f7c-6f35a3fe310c/products/badbabe1-9551-4624-99e5-51c1ed98e57f.png",
              },
              quantity: 3,
            },
            {
              product: {
                id: "0b201eb0-d739-4f47-a8e1-080e686f9e1e",
                name: "Nintendo GBA",
                price: 150,
                category: {
                  id: "894272de-3e36-4ea1-ba2b-c3e3559c3798",
                  name: "Tecnología",
                },
                epic: false,
                featured: true,
                newProduct: false,
                imgUrl:
                  "https://cdn.ndrz.io/b772df84d7a3ae30dafe651c9d714000/e9e0ff2a-7b75-4dc0-9f7c-6f35a3fe310c/products/09a7094f-d190-4b45-ad8e-d7fd738b3a9d.png",
              },
              quantity: 1,
            },
          ],
        },
      ],
    },
  },
];
// * app.groowcity.com and app.gwoocity.com representan los sitios de creación de empresas
// * gwoocity: desarrollo y pruebas, groowcity: producción
export const SITE_CREATION_HOSTS = [
  "app.groowcity.com",
  "app.gwoocity.com",
  "localhost:9545",
];

// * careers.cleveritgroup.com and localhost:4000 representan los sitios de ofertas de
// * jobyfine
export const JOBYFINE_HOSTS = ["careers.cleveritgroup.com"];

// * some public sites to be excluded later, probably is a better way to manage this
export const PUBLIC_SITES = ["/public-appreciation/"];
