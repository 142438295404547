import { useQuery, useSubscription } from "@apollo/client";
import { gqlNotification } from "gql";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { forceRefetchQueries } from "settings/apollo";
import { useUserStoreContext } from "context/userStore/userStore.provider";
import useSound from "use-sound";

const SiteHead = () => {
  const { user } = useUserStoreContext((store) => ({
    user: store.user,
  }));
  const router = useRouter();
  const { query } = router;
  const { data } = useQuery(gqlNotification.queries.NOTIFICATION_COUNT, {
    fetchPolicy: "network-only",
    skip: !user,
  });

  const [favicon, setfavicon] = useState<string>("/favicon.png");
  const [title, setTitle] = useState<string>("Groowcity");

  const [playComplete] = useSound("/sounds/feedback-sound.mp3", {
    volume: 0.5,
  });

  useSubscription(gqlNotification.subscriptions.NOTIFICATION_ADDED, {
    skip: !user,
    variables: {
      user: user?.id,
    },
    onData: async ({ data }) => {
      const { notificationCreated } = data?.data;
      if (notificationCreated.user?.id !== user?.id) {
        await forceRefetchQueries([
          gqlNotification.queries.NOTIFICATIONS,
          gqlNotification.queries.NOTIFICATION_COUNT,
        ]);
        playComplete();
      }
    },
  });

  useEffect(() => {
    if (!query.signedOut && data?.getNotificationCount > 0) {
      setfavicon("/favicon-dot.png");
      setTitle(`(${data?.getNotificationCount}) Groowcity`);
    } else {
      setfavicon("/favicon.ico");
      setTitle("Groowcity");
    }
  }, [data, query]);

  return (
    <Head>
      <link rel="icon" type="image/x-icon" href={favicon} />
      <title>{title}</title>
    </Head>
  );
};

export default SiteHead;
