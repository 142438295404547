import Router from "next/router";
import React, { cloneElement, isValidElement, useEffect } from "react";
import { useUserStoreContext } from "context/userStore/userStore.provider";

const PrivateRoute = ({ children }) => {
  const { user } = useUserStoreContext((store) => ({
    user: store.user,
  }));
  // * Redirect to home if not logged in2
  useEffect(() => {
    if (!user) {
      Router.replace("/login");
    }
  }, [user]);

  return React.Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child);
    }

    return child;
  });
};

export default React.memo(PrivateRoute);
