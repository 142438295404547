import {
  ICONS_ACTIVE_VERSION,
  isSvg,
} from "components/Layout/Sidebar/components/body/content/utils/iconModulesPath";
import Image from "next/image";
import { useMemo } from "react";

interface ISvgProps {
  readonly src: string;
  readonly width?: number;
  readonly height?: number;
  readonly onClick?: () => void;
  readonly className?: string;
  readonly alt?: string;
  readonly moduleOpacity?: boolean;
}
export default function SVG(props: ISvgProps) {
  const {
    width,
    height,
    src,
    onClick,
    className,
    alt,
    moduleOpacity = true,
  } = props;

  // Verifica si el src comienza con '/icon/modules/' y tiene '/v2' en algún nivel posterior
  const isIconVersionV2 = /^\/icon\/modules\/.*\/v2\//.test(src);

  return useMemo(
    () => (
      <Image
        {...{ src, onClick }}
        className={`${onClick ? "cursor-pointer" : ""} ${className} ${ICONS_ACTIVE_VERSION() === "v2" && (isIconVersionV2 ? "opacity-50" : "")} ${isIconVersionV2 ? "dark:invert" : ""}`}
        width={width || 100}
        height={height || 100}
        alt={alt || "SVG"}
        unoptimized={isSvg(src)}
      />
    ),
    [
      src,
      onClick,
      className,
      width,
      height,
      alt,
      moduleOpacity,
      isIconVersionV2,
    ] // Add necessary dependencies here
  );
}
