import { useWindowSize } from "@react-hook/window-size";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { FC, ReactNode, useEffect, useState } from "react";
import { useUserStoreContext } from "context/userStore/userStore.provider";
import { PRIVATE_ROUTES } from "../../../routes/routes";
import SidebarLayoutHeader from "./Header";
import MainMenuDynamic from "./MainMenuDynamic";
import MainMenuStatic from "./MainMenuStatic";
import Sidebar from "./Sidebar";
import SubMenuDynamic from "./SubMenuDynamic";
import SubMenuStatic from "./SubMenuStatic";
import {
  IMenuList,
  IMenuListItem,
  ISubMenuLists,
  menuItemByPath,
} from "./utils";

interface SidebarLayoutProps {
  children: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = ({ children }) => {
  const { t, lang } = useTranslation("common");

  const MAIN_MENU_CONFIG: IMenuListItem = {
    name: t("Configuración"),
    icon: "config",
  };
  const MAIN_MENU_LISTS: IMenuList[] = [
    {
      header: "",
      items: [
        {
          name: t("Mi perfil"),
          icon: "my-space",
          route: PRIVATE_ROUTES.profile,
        },
        {
          name: t("Dashboard"),
          icon: "dashboard",
          route: PRIVATE_ROUTES.dashboard,
        },
        {
          name: t("Dashboard organizacional"),
          icon: "dashboard-organization",
          route: PRIVATE_ROUTES.organizationDashboard,
        },
        {
          name: t("Mi plan de carrera"),
          icon: "career-plan",
          route: PRIVATE_ROUTES.myCareer,
        },
        {
          name: t("Mis equipos"),
          route: PRIVATE_ROUTES.teamsLeader,
          icon: "team",
        },
        {
          name: t("Mis evaluaciones"),
          icon: "test",
          route: PRIVATE_ROUTES.myAppreciations,
        },
        {
          name: t("Appreciations"),
          icon: "appreciation",
          route: PRIVATE_ROUTES.appreciationList,
        },
        {
          name: t("Preselección"),
          icon: "choice",
          route: PRIVATE_ROUTES.publicAppreciation,
        },
        {
          name: t("Planes de acción"),
          icon: "admin-action-plans",
          route: PRIVATE_ROUTES.actionPlans,
        },
        {
          name: t("Actividades"),
          icon: "activity",
          route: PRIVATE_ROUTES.activities,
        },
      ],
    },
  ];

  const SUBMENU_LISTS: ISubMenuLists = {
    [t("Configuración")]: [
      {
        header: t("Configuración"),
        items: [
          {
            name: t("Roles"),
            icon: "identity",
            route: PRIVATE_ROUTES.roleMaintainer,
          },
          {
            name: t("Areas"),
            icon: "identity",
            route: PRIVATE_ROUTES.roleMaintainer,
          },
          {
            name: t("Dimensiones"),
            icon: "skills",
            route: PRIVATE_ROUTES.dimensionMaintainer,
          },
          {
            name: t("Carreras"),
            icon: "education",
            route: PRIVATE_ROUTES.coursesMaintainer,
          },
          {
            name: t("Material multimedia"),
            icon: "multimedia",
            route: PRIVATE_ROUTES.multimediaMaintainer,
          },
          {
            name: t("Elementos de carrera"),
            icon: "list",
            route: PRIVATE_ROUTES.itemPathMantainers,
          },
          {
            name: t("Usuarios"),
            icon: "users",
            route: PRIVATE_ROUTES.userMaintainer,
          },
          {
            name: t("Equipos"),
            icon: "people",
            route: PRIVATE_ROUTES.teamMaintainer,
          },
          {
            name: t("Preguntas"),
            icon: "question",
            route: PRIVATE_ROUTES.questionMantainers,
          },
          {
            name: t("Sitio"),
            icon: "site-config",
            route: PRIVATE_ROUTES.configMantainer,
          },
        ],
      },
    ],
  };

  const router = useRouter();
  const [windowX] = useWindowSize();
  const isMobile = windowX < 768;
  const { user } = useUserStoreContext((store) => ({
    user: store.user,
  }));

  const [selectedMenuItem, setSelectedMenuItem] =
    useState<IMenuListItem | null>();

  const [mainMenuList, setMainMenuList] = useState<IMenuList[]>([]);
  const [subMenuList, setSubMenuList] = useState<ISubMenuLists>({});
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isMenuClose, setIsMenuClose] = useState<boolean>(true);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false);

  // * Set lists with route guard
  useEffect(() => {
    const getNewMenuList = (menuList: IMenuList): IMenuList => {
      const newItem: IMenuList = {
        header: menuList.header,
        items: [],
      };

      menuList.items.forEach((menuItem) => {
        // * Add route if there are no roleguards or, if there are, the ones that include the user's
        const addRoute =
          !menuItem.route?.roleGuards ||
          menuItem.route.roleGuards.includes(user?.systemRole);

        if (addRoute) {
          newItem.items.push(menuItem);
        }
      });

      return newItem;
    };

    // * Main menu
    const newMenuList: IMenuList[] = [];
    MAIN_MENU_LISTS.forEach((item) => {
      newMenuList.push(getNewMenuList(item));
    });

    // * Sub menu
    const newSubmenuList: ISubMenuLists = {};
    Object.keys(SUBMENU_LISTS).forEach((key) => {
      newSubmenuList[key] = [];

      SUBMENU_LISTS[key].forEach((item) => {
        newSubmenuList[key].push(getNewMenuList(item));
      });
    });

    if (
      "Configuración" in newSubmenuList &&
      newSubmenuList["Configuración"][0]?.items?.length
    ) {
      newMenuList[0]?.items?.push(MAIN_MENU_CONFIG);
    }

    if (
      "Settings" in newSubmenuList &&
      newSubmenuList.Settings[0]?.items?.length
    ) {
      newMenuList[0]?.items?.push(MAIN_MENU_CONFIG);
    }

    setMainMenuList(newMenuList);
    setSubMenuList(newSubmenuList);
  }, [user, lang]);

  // * Set init values from link
  useEffect(() => {
    try {
      const initialPath = router.route;

      // * Get the corresponding main menu item
      let newSelectedMenuItem: IMenuListItem | null = menuItemByPath(
        mainMenuList,
        initialPath
      );

      // * If the initialPath is not in the main menu, set it to "Config" only if the user is on submenu route
      if (!newSelectedMenuItem) {
        newSelectedMenuItem = menuItemByPath(
          Object.values(subMenuList).flat(),
          initialPath
        )
          ? MAIN_MENU_CONFIG
          : null;
      }

      setSelectedMenuItem(newSelectedMenuItem);
    } catch (error) {
      console.error("error: ", error);
    }
  }, [mainMenuList, subMenuList, lang]);

  const onMainMenuItemClick = (item: IMenuListItem) => {
    setSelectedMenuItem(item);
    setIsMenuOpen(false);
    setIsSubMenuOpen(!!subMenuList[item.name]);

    if (item?.route?.path) {
      router.push(item.route?.path);
    }
  };

  const onSubMenuItemClick = (item: IMenuListItem) => {
    if (item?.route?.path) {
      router.push(item.route?.path);
    }

    if (isMobile) {
      setIsMenuOpen(false);
      setIsSubMenuOpen(false);
    }
  };

  const onHamburgerClick = () => {
    if (isMenuOpen || isSubMenuOpen) {
      setIsMenuOpen(false);
      setIsSubMenuOpen(false);
      return;
    }

    setIsMenuOpen(true);
  };

  const onSubMenuBackMobile = () => {
    setIsMenuOpen(true);
    setIsSubMenuOpen(false);
  };

  const getContentStyle = (): { width: string; tx: string } => {
    let width = "95vw";
    let tx = "5vw";

    if (isSubMenuOpen) {
      width = "max(75vw, calc(90vw - 260px))";
      tx = "min(max(25vw, 280px), 550px)";
      return { width, tx };
    }

    if (isMobile) {
      width = "100%";
      tx = "0vw";
      return { width, tx };
    }

    // * If there's a small submenu
    if (subMenuList[selectedMenuItem?.name]) {
      width = "91vw";
      tx = "9vw";
      return { width, tx };
    }

    return { width, tx };
  };

  const contentStyle = getContentStyle();

  return (
    <div className="flex h-screen w-screen" style={{ zIndex: 100 }}>
      <SidebarLayoutHeader {...{ isMenuOpen, onHamburgerClick }} />

      <div
        id="body-container"
        className="relative h-screen w-full"
        style={{ marginTop: isMobile ? "10vh" : "0" }}
      >
        {(!isMobile || (isMobile && (isMenuOpen || isSubMenuOpen))) && (
          <div
            id="sidebars"
            className="fixed z-7 h-screen"
            style={{
              width: isMobile ? "100vw" : "25vw",
              minWidth: "280px",
              maxWidth: "550px",
              pointerEvents: "none",
            }}
          >
            <Sidebar
              className="absolute"
              style={{ zIndex: isMenuOpen ? "20" : "0" }}
              dynamicMenuStyle={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              staticMenuStyle={{
                width: isMenuClose && !isMobile ? "20%" : "100%",
                height: "95%",
              }}
              isOpen={isMenuOpen}
              autoClose={!isMobile}
              onOpenStart={() => {
                setIsMenuOpen(true);
                setIsMenuClose(false);
              }}
              onClose={() => {
                setIsMenuOpen(false);
                setIsMenuClose(true);
              }}
              staticMenu={
                <MainMenuStatic
                  {...{ isMenuOpen }}
                  onItemClick={onMainMenuItemClick}
                  lists={mainMenuList}
                />
              }
              dynamicMenu={<MainMenuDynamic />}
            />

            {!!subMenuList[selectedMenuItem?.name] && (
              <Sidebar
                id="SUBMENU"
                style={{
                  width: isMobile ? "100%" : "80%",
                  marginLeft: isMobile ? "0" : "20%",
                }}
                className="absolute"
                onOpenStart={() => setIsSubMenuOpen(true)}
                onCloseStart={() => setIsSubMenuOpen(false)}
                autoClose={false}
                autoOpen={false}
                isOpen={isSubMenuOpen}
                staticMenuStyle={{
                  width: "100%",
                  height: "95%",
                }}
                staticMenu={
                  <SubMenuStatic
                    {...{ selectedMenuItem }}
                    lists={subMenuList}
                    onItemClick={onSubMenuItemClick}
                    onBackMobile={onSubMenuBackMobile}
                  />
                }
                dynamicMenu={
                  <SubMenuDynamic
                    {...{ selectedMenuItem }}
                    isMenuOpen={isSubMenuOpen}
                    setIsSubMenuOpen={setIsSubMenuOpen}
                  />
                }
              />
            )}
          </div>
        )}

        <div
          id="content"
          className="transition-all"
          style={{
            width: `${contentStyle.width}`,
            marginLeft: contentStyle.tx,
            marginTop: isMobile ? "0" : "10vh",
            height: "90vh",
            padding: isMobile ? "0" : "1.25rem",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default SidebarLayout;
