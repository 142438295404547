import { useMsal } from "@azure/msal-react";
import { useWindowSize } from "@react-hook/window-size";
import Avatar from "boring-avatars";
import Icon from "components/Icon";
import { useUserStoreContext } from "context/userStore/userStore.provider";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { Dropdown } from "rsuite";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "../../../routes/routes";

import ButtonFeedback from "../../Modal/Feedback/ButtonFeedback";
import SVG from "../../SVG";
import ToggleTheme from "../../ToggleTheme/ToggleTheme";
import ToggleVendor from "../../ToggleVendor/ToggleVendor";

interface ISidebarLayoutHeader {
  isMenuOpen: boolean;
  onHamburgerClick: () => void;
}

const SidebarLayoutHeader = (props: ISidebarLayoutHeader) => {
  const { onHamburgerClick } = props;
  const { instance } = useMsal();

  const { user } = useUserStoreContext((store) => ({
    ...store,
  }));

  const router = useRouter();
  const { t } = useTranslation("common");

  const [windowX] = useWindowSize();
  const isMobile = windowX < 768;

  const handleRedirect = (url: string) => {
    router.push({ pathname: url });
  };

  const handleSignout = () => {
    router.push(PUBLIC_ROUTES.logout.path);
  };

  return (
    <div
      id="header"
      className="fixed flex w-full justify-between bg-gray-50 p-5 dark:bg-gray-900"
      style={{ height: "10vh", zIndex: 7 }}
    >
      <div className="flex items-center justify-center">
        {isMobile && (
          <div
            className="z-50 flex cursor-pointer items-center justify-center"
            onClick={onHamburgerClick}
          >
            <SVG src="/svg/hamburger.svg" width={30} height={30} />
          </div>
        )}
      </div>

      <div className="flex items-center">
        <div className="mr-3">
          <ToggleVendor />
        </div>

        <div className="mr-3">
          <ButtonFeedback />
        </div>

        <div className="mr-3">
          <ToggleTheme />
        </div>

        <div className="flex flex-col">
          <Dropdown
            placement="bottomEnd"
            renderToggle={(props, ref) => (
              <div className="flex items-center" {...props} ref={ref}>
                {user?.profileImage === DEFAULT_AVATAR ||
                !user?.profileImage ? (
                  <Avatar
                    size={40}
                    name={`${user?.name} ${user?.lastname}`}
                    variant="beam"
                    colors={AVATAR_COLORS}
                  />
                ) : (
                  <div
                    className="h-12 w-12 rounded-full border-2 border-current-500 bg-gray-900 bg-cover bg-center dark:bg-white"
                    style={{
                      backgroundImage: `url(${user?.profileImage})`,
                    }}
                  />
                )}
                {windowX >= WIDTH_MD && (
                  <div className="ml-2 text-base">
                    {user?.name || user?.lastname}
                  </div>
                )}
              </div>
            )}
          >
            <Dropdown.Item
              onClick={() => handleRedirect(PRIVATE_ROUTES.profile.path)}
            >
              <Icon icon="user" className="mr-2" />
              <span className="text-500">{t("Perfil")}</span>
            </Dropdown.Item>
            <Dropdown.Item onClick={handleSignout}>
              <Icon icon="sign-out-alt" className="text-500 mr-2" />
              <span className="text-red-500">{t("Cerrar sesión")}</span>
            </Dropdown.Item>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default SidebarLayoutHeader;
