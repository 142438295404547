import useAppStore from "stores/useAppStore";
import { IBasketMemoryItem, IProduct } from "types/Product.type";
import { IUser, IUserOnboarding } from "types/User.types";
import { create } from "zustand";
import { persist } from "zustand/middleware";

// Define el tipo UserState
export interface UserState {
  user: IUser | null;
  updateUserData: (data: Partial<IUser>) => void;
  refreshToken: string | null;
  setRefreshToken: (refreshToken: string) => void;
  accessToken: string | null;
  setAccessToken: (accessToken: string) => void;
  setUser: (user: IUser | null) => void;
  onboardingData: IUserOnboarding | null;
  setOnboardingData: (onboardingData: IUserOnboarding) => void;
  logout: () => void;
  signoutNewOrganization: () => void;
  authenticate: ({ accessToken }: { accessToken: string }) => void;
  addProduct: (product: IProduct) => void;
  removeProduct: (product: IProduct, removeAllProduct?: boolean) => void;
  basketProducts: IBasketMemoryItem[];
  basketProductCount: number;
  setBasketProductCount: (basketProductCount: number) => void;
  setBasketProducts: (products: IBasketMemoryItem[]) => void;
  setRemovingProduct: (removingProduct: boolean) => void;
  removingProduct: boolean;
  addingProduct: boolean;
  setAddingProduct: (addingProduct: boolean) => void;
  removingAllProduct: boolean;
  setRemovingAllProduct: (removingAllProduct: boolean) => void;
  addCoins: (coins: number) => void;
  removeCoins: (coins: number) => void;
  showHelperTooltip?: boolean;
  setShowHelperTooltip?: (showHelperTooltip: boolean) => void;
  toggleHelperTooltip?: () => void;
}

// Creación del store usando Zustand
export const useUserStore = create<UserState>()(
  persist(
    (set, get) => {
      // Inicializa useAppStore
      const { initialize } = useAppStore.getState();

      // Llama a initialize para cambiar isInitialized a true
      initialize();

      return {
        user: null,
        setUser: (user) => set({ user }),
        updateUserData: (data: Partial<IUser>) => {
          set((state) => {
            const updatedUser = { ...state.user, ...(data as IUser) };

            if (typeof updatedUser.id !== "string") {
              updatedUser.id = updatedUser.id || "";
            }

            return { ...state, user: updatedUser };
          });
        },
        refreshToken: null,
        setRefreshToken: (refreshToken) => set({ refreshToken }),
        accessToken: null,
        setAccessToken: (accessToken) => set({ accessToken }),
        onboardingData: null,
        setOnboardingData: (onboardingData) => set({ onboardingData }),
        logout: () =>
          set({ user: null, refreshToken: null, accessToken: null }),
        signoutNewOrganization: () => {
          set({ user: null, refreshToken: null, accessToken: null });
          window.location.reload();
        },
        authenticate: async ({ accessToken }) => {
          set({ accessToken });
        },
        addProduct: (product: IProduct) => {
          set((state) => {
            const productIndex = state.basketProducts.findIndex(
              (p) => p.product.id === product.id
            );

            let newBasketProducts = [...state.basketProducts];
            let newBasketProductCount;

            if (productIndex > -1) {
              newBasketProducts[productIndex].quantity += 1;
            } else {
              newBasketProducts = [
                ...state.basketProducts,
                { product, quantity: 1 },
              ];
            }

            newBasketProductCount = newBasketProducts.reduce(
              (count, item) => count + item.quantity,
              0
            );

            return {
              ...state,
              basketProducts: newBasketProducts,
              basketProductCount: newBasketProductCount,
            };
          });
        },
        removeProduct: (product: IProduct, removeAllProduct = false) => {
          set((state) => {
            const productIndex = state.basketProducts.findIndex(
              (p) => p.product.id === product.id
            );
            const newProducts = [...state.basketProducts];

            if (productIndex > -1) {
              if (removeAllProduct) {
                newProducts.splice(productIndex, 1);
              } else {
                newProducts[productIndex].quantity -= 1;
                if (newProducts[productIndex].quantity === 0) {
                  newProducts.splice(productIndex, 1);
                }
              }

              const newBasketProductCount = newProducts.reduce(
                (count, item) => count + item.quantity,
                0
              );

              return {
                ...state,
                basketProducts: newProducts,
                basketProductCount: newBasketProductCount,
              };
            }

            return state;
          });
        },
        basketProducts: [],
        setBasketProducts: (basketProducts) => {
          const basketProductCount = basketProducts.reduce(
            (count, item) => count + item.quantity,
            0
          );
          set({ basketProducts, basketProductCount });
        },
        basketProductCount: 0,
        setBasketProductCount: (basketProductCount) =>
          set({ basketProductCount }),
        removingProduct: false,
        addingProduct: false,
        removingAllProduct: false,
        setRemovingProduct: (removingProduct) => set({ removingProduct }),
        setAddingProduct: (addingProduct) => set({ addingProduct }),
        setRemovingAllProduct: (removingAllProduct) =>
          set({ removingAllProduct }),
        addCoins: (coins: number) => {
          set((state) => {
            const updatedUser = {
              ...state.user,
              coins: state.user?.coins ? state.user.coins + coins : coins,
            } as IUser;

            return { ...state, user: updatedUser };
          });
        },
        removeCoins: (coins: number) => {
          set((state) => {
            const updatedUser = {
              ...state.user,
              coins: state.user?.coins ? state.user.coins - coins : 0,
            } as IUser;

            return { ...state, user: updatedUser };
          });
        },
        showHelperTooltip: true,
        setShowHelperTooltip: (showHelperTooltip) => set({ showHelperTooltip }),
        toggleHelperTooltip: () =>
          set((state) => ({ showHelperTooltip: !state.showHelperTooltip })),
      };
    },
    {
      version: 1,
      name: "user-store",
    }
  )
);
