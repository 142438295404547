export type IActiveVersion = "v1" | "v2";

export const ICONS_ACTIVE_VERSION = () => {
  // if (typeof window !== "undefined") {
  //   const vendorName = localStorage.getItem("vendorName");
  //   return vendorName === "Dunder-mifflin" ? "v2" : "v1";
  // }
  return "v2"; // Default value or handle accordingly
};

export function isSvgUrl(url: string): boolean {
  return typeof url === "string" && url.endsWith(".svg");
}

export function isSvg(urlOrContent: string): boolean {
  return isSvgUrl(urlOrContent);
}

export const ICONS_MODULES_PATHS = {
  LEARNING: {
    EVALUATIONS_MODULE: `/icon/modules/learning/${ICONS_ACTIVE_VERSION()}/evaluations-module.svg`,
    APPRECIATION: `/icon/modules/learning/${ICONS_ACTIVE_VERSION()}/appreciation.svg`,
    MY_EVALUATIONS: `/icon/modules/learning/${ICONS_ACTIVE_VERSION()}/my-evaluations.svg`,
    RESOURCES: `/icon/modules/learning/${ICONS_ACTIVE_VERSION()}/resources.svg`,
  },
  RADAR: {
    RADAR_MODULE: `/icon/modules/radar/${ICONS_ACTIVE_VERSION()}/radar-module.svg`,
    TEAMS: `/icon/modules/radar/${ICONS_ACTIVE_VERSION()}/teams.svg`,
  },
  EXPLORE: {
    EXPLORE_MODULE: `/icon/modules/explore/${ICONS_ACTIVE_VERSION()}/explore-module.svg`,
    ANNIVERSARIES_MODULE: `/icon/modules/explore/${ICONS_ACTIVE_VERSION()}/anniversaries-module.svg`,
  },
  CHALLENGES: {
    CHALLENGE_GROUP: `/icon/modules/challenges/${ICONS_ACTIVE_VERSION()}/challenge-group.svg`,
    USER_CHALLENGES: `/icon/modules/challenges/${ICONS_ACTIVE_VERSION()}/user-challenges.svg`,
    CREATE_CHALLENGES: `/icon/modules/challenges/${ICONS_ACTIVE_VERSION()}/create-challenges.svg`,
    RANKING: `/icon/modules/challenges/${ICONS_ACTIVE_VERSION()}/ranking.svg`,
  },
  ACTION_PLANS: {
    ACTION_PLAN_MODULE: `/icon/modules/actionPlans/${ICONS_ACTIVE_VERSION()}/action-plan-module.svg`,
    ACTION_PLAN_GROUP: `/icon/modules/actionPlans/${ICONS_ACTIVE_VERSION()}/action-plan-group.svg`,
    MY_ACTION_PLAN: `/icon/modules/actionPlans/${ICONS_ACTIVE_VERSION()}/my-action-plan.svg`,
    CREATE_ACTION_PLAN: `/icon/modules/actionPlans/${ICONS_ACTIVE_VERSION()}/create-action-plan.svg`,
  },
  SURVEYS: {
    SURVEY_MODULE: `/icon/modules/surveys/${ICONS_ACTIVE_VERSION()}/${ICONS_ACTIVE_VERSION() === "v1" ? "survey-module.png" : "survey-module.svg"}`,
    SURVEY_GROUP: `/icon/modules/surveys/${ICONS_ACTIVE_VERSION()}/${ICONS_ACTIVE_VERSION() === "v1" ? "survey-group.png" : "survey-group.svg"}`,
    SURVEY: `/icon/modules/surveys/${ICONS_ACTIVE_VERSION()}/${ICONS_ACTIVE_VERSION() === "v1" ? "survey-group.png" : "survey.svg"}`,
    MY_SURVEY: `/icon/modules/surveys/${ICONS_ACTIVE_VERSION()}/my-survey.svg`,
  },
  MARKETPLACE: {
    MARKETPLACE_GROUP: `/icon/modules/marketplace/${ICONS_ACTIVE_VERSION()}/marketplace-group.svg`,
    COINS_MODULE: `/icon/modules/marketplace/${ICONS_ACTIVE_VERSION()}/coins/coins-module.svg`,
    COINS_GROUP: `/icon/modules/marketplace/${ICONS_ACTIVE_VERSION()}/coins/coins-group.svg`,
    PRODUCTS: `/icon/modules/marketplace/${ICONS_ACTIVE_VERSION()}/coins/products.svg`,
    PRODUCTS_CATEGORY: `/icon/modules/marketplace/${ICONS_ACTIVE_VERSION()}/coins/products-category.svg`,
    COINS_CONCEPT: `/icon/modules/marketplace/${ICONS_ACTIVE_VERSION()}/coins/coins-concept.svg`,
    EXCHANGE_CONTROL: `/icon/modules/marketplace/${ICONS_ACTIVE_VERSION()}/coins/exchange-control.svg`,
    ACTIVITIES: `/icon/modules/marketplace/${ICONS_ACTIVE_VERSION()}/coins/activities.svg`,
  },
  CAREER_PLAN: {
    MODULE: `/icon/modules/careerPlan/${ICONS_ACTIVE_VERSION()}/career-plan-module.svg`,
    CAREER_GROUP: `/icon/modules/careerPlan/${ICONS_ACTIVE_VERSION()}/career-group.svg`,
    MY_DEVELOPMENT_PLAN: `/icon/modules/careerPlan/${ICONS_ACTIVE_VERSION()}/my-development-plan.svg`,
    DEVELOPMENT_PHASES: `/icon/modules/careerPlan/${ICONS_ACTIVE_VERSION()}/development-phases.svg`,
    DEVELOPMENT_PLANS: `/icon/modules/careerPlan/${ICONS_ACTIVE_VERSION()}/development-plans.svg`,
  },
  JOBYFINE: {
    JOBYFINE_GROUP: `/icon/modules/jobyfine/${ICONS_ACTIVE_VERSION()}/jobyfine-group.svg`,
    REQUESTS: `/icon/modules/jobyfine/${ICONS_ACTIVE_VERSION()}/requests.svg`,
    JOB_APPLICANTS: `/icon/modules/jobyfine/${ICONS_ACTIVE_VERSION()}/job-applicants.svg`,
    OFFERS: `/icon/modules/jobyfine/${ICONS_ACTIVE_VERSION()}/offers.svg`,
    PROCESS: `/icon/modules/jobyfine/${ICONS_ACTIVE_VERSION()}/process.svg`,
    FINDER: `/icon/modules/jobyfine/${ICONS_ACTIVE_VERSION()}/finder.svg`,
    JOBYFINE_PRESELECTION: `/icon/modules/jobyfine/${ICONS_ACTIVE_VERSION()}/preselection.svg`,
  },
  CONFIG: {
    CONFIG_GROUP: `/icon/modules/config/${ICONS_ACTIVE_VERSION()}/config-group.svg`,
    POSITION: `/icon/modules/config/${ICONS_ACTIVE_VERSION()}/position.svg`,
    DIVISION: `/icon/modules/config/${ICONS_ACTIVE_VERSION()}/division.svg`,
    AREA: `/icon/modules/config/${ICONS_ACTIVE_VERSION()}/area.svg`,
    SKILLS: `/icon/modules/config/${ICONS_ACTIVE_VERSION()}/skills.svg`,
    CERTIFICATIONS: `/icon/modules/config/${ICONS_ACTIVE_VERSION()}/certifications.svg`,
    USERS: `/icon/modules/config/${ICONS_ACTIVE_VERSION()}/users.svg`,
    QUESTIONS: `/icon/modules/config/${ICONS_ACTIVE_VERSION()}/questions.svg`,
    RESOURCES: `/icon/modules/learning/${ICONS_ACTIVE_VERSION()}/resources.svg`,
    APP_SETTING: `/icon/modules/config/${ICONS_ACTIVE_VERSION()}/app-setting.svg`,
    CORPORATE_VALUES: `/icon/modules/config/${ICONS_ACTIVE_VERSION()}/corporate-values.svg`,
    VACATIONS: `/icon/modules/config/${ICONS_ACTIVE_VERSION()}/vacations.svg`,
    ANONYMOUS_MAIL: `/icon/modules/config/${ICONS_ACTIVE_VERSION()}/anonymous-mail.svg`,
    GOALS: `/icon/modules/config/${ICONS_ACTIVE_VERSION()}/goals-module.svg`,
    LINKS_IN_EMAIL: `/icon/modules/config/${ICONS_ACTIVE_VERSION()}/links-in-email.svg`,
    NOTIFICATION_ICON: `/icon/modules/config/${ICONS_ACTIVE_VERSION()}/notification.svg`,
    LOGIN_MICROSOFT: "/icon/modules/config/login-microsoft-module.svg",
    LOGIN_LINKEDIN: "/icon/modules/config/login-linkedin-module.svg",
    ACTIVE_DIRECTORY: "/icon/modules/config/active-directory-module.svg",
    FLOKZU: "/icon/modules/config/flokzu-module.svg",
  },
  SUPER_ADMIN: {
    SUPER_ADMIN_GROUP: `/icon/modules/superAdmin/${ICONS_ACTIVE_VERSION()}/super-admin-group.svg`,
    FEEDBACK: `/icon/modules/superAdmin/${ICONS_ACTIVE_VERSION()}/feedback.svg`,
    PUBLIC_CHALLENGE: `/icon/modules/superAdmin/${ICONS_ACTIVE_VERSION()}/public-challenge.svg`,
  },
};
