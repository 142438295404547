import React, { createContext, ReactNode, useContext, useState } from "react";
import { IVendor } from "types/Vendor.types";

// Definir el tipo de contexto
interface VendorContextType {
  vendor: IVendor | null;
  setVendor: (vendor: IVendor | null) => void;
}

// Crear el contexto con un valor predeterminado
const VendorContext = createContext<VendorContextType | undefined>(undefined);

// Proveedor del contexto
export const VendorProvider: React.FC<{
  vendor: IVendor | null;
  children: ReactNode;
}> = ({ vendor: initialVendor, children }) => {
  const [vendor, setVendor] = useState<IVendor | null>(initialVendor);

  return (
    <VendorContext.Provider value={{ vendor, setVendor }}>
      {children}
    </VendorContext.Provider>
  );
};

// Hook personalizado para usar el contexto
export const useVendor = (): VendorContextType => {
  const context = useContext(VendorContext);
  if (!context) {
    throw new Error("useVendor must be used within a VendorProvider");
  }
  return context;
};
