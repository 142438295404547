import { gql } from "@apollo/client";
import { IRecognition } from "types/Recognition.type";

export interface IRecognitionsResponse {
  getRecognitionCount: IRecognition[];
}

export interface IRecognitionByCorporateValue {
  getRecognitionByUser: IRecognitionByUser[];
}

export interface IRecognitionByUser {
  name: string;
  lastname: string;
  email: string;
  profileImage: string;
  recognitionMessage: string;
  recognitionDate: string;
  recognitionId: string;
}

export interface IRecognitionByUserResponse {
  total: number;
  totalPages: number;
  userRecognitions: IRecognitionByUser[];
}

export interface IRecognitionByUserPaginated {
  getRecognitionByUser: IRecognitionByUserResponse;
}

const GET_RECOGNITIONS = gql`
  query getRecognitionCount {
    getRecognitionCount {
      avatar
      corporateValueTitle
      corporateValueId
      quantityReceived
      quantitySend
    }
  }
`;

const GET_RECOGNITIONS_BY_CORPORATE_VALUE_PAGINATED = gql`
  query GetRecognitionByUser(
    $corporateValueByUserPaginated: CorporateValueByUserPaginatedInput!
  ) {
    getRecognitionByUser(
      corporateValueByUserPaginated: $corporateValueByUserPaginated
    ) {
      total
      totalPages
      userRecognitions {
        email
        lastname
        name
        profileImage
        recognitionDate
        recognitionId
        recognitionMessage
        userId
      }
    }
  }
`;

export default {
  GET_RECOGNITIONS,
  GET_RECOGNITIONS_BY_CORPORATE_VALUE_PAGINATED,
};
