import dynamic from "next/dynamic";
import { FC, ReactNode } from "react";

interface PortalLayoutProps {
  children: ReactNode;
}

const PortalNavBarNoSSR = dynamic(
  () => import("components/_Pages/Portal/NavBar/PortalNavBar"),
  {
    ssr: false,
  }
);

const PortalLayout: FC<PortalLayoutProps> = ({ children }) => (
  <div className="h-screen min-w-full">
    <PortalNavBarNoSSR />
    <div
      style={{
        minHeight: "calc(100vh - 100px)",
        paddingTop: "60px",
      }}
    >
      <div className="animate__animated" id="layoutMainContent">
        <div className="h-full min-h-full">{children}</div>
      </div>
    </div>
  </div>
);

export default PortalLayout;
