import { useMutation } from "@apollo/client";
import { SELECTED_VENDOR } from "@constants/global";
import { gqlUser } from "gql";
import useTranslation from "next-translate/useTranslation";
import { Button, SelectPicker } from "rsuite";
import { useUserStoreContext } from "context/userStore/userStore.provider";

const ToggleVendor = () => {
  const [updateUserSelectedVendor] = useMutation(
    gqlUser.mutations.UPDATE_USER_SELECTED_VENDOR
  );
  const { t } = useTranslation("common");
  const { user } = useUserStoreContext((store) => ({
    user: store.user,
  }));

  const handleOnChangeVendor = async (data) => {
    await updateUserSelectedVendor({
      variables: {
        updateUserSelectedVendorInput: { vendorId: data },
      },
    });

    localStorage.setItem(SELECTED_VENDOR, data);
    window.location.reload();
  };

  return (
    <>
      {user?.vendorList?.length > 1 && (
        <SelectPicker
          className="mr-2"
          data={user?.vendorList}
          labelKey="name"
          valueKey="id"
          cleanable={false}
          searchable
          onChange={handleOnChangeVendor}
          style={{ width: "100%" }}
          defaultValue={user?.selectedVendorId}
          placeholder={t("Seleccione")}
          renderMenuItem={(label, item) => (
            <div className="flex items-center justify-between">
              <span>
                <b>{label}</b> - {item?.id}
              </span>

              <Button
                onClick={() => handleOnChangeVendor(item?.id)}
                appearance="primary"
              >
                {t("Seleccionar")}
              </Button>
            </div>
          )}
        />
      )}
    </>
  );
};

export default ToggleVendor;
