"use client";

import { createContext, ReactNode, useContext } from "react";
import { UserState, useUserStore } from "stores/user/userStore";

// Crear el contexto con un valor inicial de undefined
const UserStoreContext = createContext<UserState | undefined>(undefined);

// Proveedor del contexto
export const UserStoreProvider = ({ children }: { children: ReactNode }) => {
  // Llamar a `useUserStore` siempre en el mismo lugar
  const store = useUserStore();

  return (
    <UserStoreContext.Provider value={store}>
      {children}
    </UserStoreContext.Provider>
  );
};

// Hook para usar el contexto
export const useUserStoreContext = <T,>(
  selector: (store: UserState) => T
): T => {
  const store = useContext(UserStoreContext);
  if (!store) {
    throw new Error(
      "useUserStoreContext must be used within a UserStoreProvider"
    );
  }
  return selector(store); // Aplicar el selector al store
};
