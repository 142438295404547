import Icon from "components/Icon";
import { APP_VERSION } from "components/Roadmap/ItemPathNode/constants";
import { useUserStoreContext } from "context/userStore/userStore.provider";
import { Building2 } from "lucide-react";
import moment from "moment";
import useTranslation from "next-translate/useTranslation";
import toast from "react-hot-toast";
import { useDesktopSidebarStore } from "stores/sidebar/useDesktopSidebarStore";

export default function SidebarFooter() {
  const { t } = useTranslation("common");
  const { user } = useUserStoreContext((store) => ({
    user: store.user,
  }));
  const { isExpanded } = useDesktopSidebarStore();

  const handleBeamer = () => {
    // * open beamer .beamerTrigger
    // * Your custom button handler to toggle Beamer
    if (window["Beamer"]) {
      window["Beamer"].toggle();
    }
  };
  return (
    <div className="grid gap-4 border-t bg-sidebar-footer px-4 shadow-custom-top dark:border-gray-600 dark:bg-gray-900">
      {/* Section Vendor */}
      <div
        className="mt-2 flex w-auto cursor-pointer items-center justify-center gap-1 rounded-md border border-gray-400 py-1 dark:border-gray-600"
        onClick={() => {
          navigator.clipboard.writeText(user?.selectedVendorId || "");
          toast.success(t("ID Copiado"));
        }}
      >
        <Building2 size={18} color="gray" />
        {isExpanded && <span>{user?.selectedVendor?.name}</span>}
      </div>

      {/* Section What is the New on GroowCity */}
      <div
        className="pointer flex cursor-pointer flex-col items-center justify-center border-gray-400 text-gray-800 hover:text-blue-600 dark:border-gray-700 dark:text-gray-400 dark:hover:text-green-600"
        onClick={handleBeamer}
      >
        <div className="flex items-center justify-center align-middle">
          <Icon icon={["far", "history"]} />
          {isExpanded && (
            <span className="ml-1 text-xs font-semibold">
              {t("Qué hay de nuevo en Groow?")}
            </span>
          )}
        </div>
      </div>

      {/* Section GroowCity Version */}
      <div className="ark:border-gray-700 mb-2 flex flex-col items-center justify-center border-gray-400">
        {isExpanded && <span>Groowcity ® {moment().format("YYYY")}</span>}
        <span className="text-xs text-primary dark:text-green-200">
          {APP_VERSION}
        </span>
      </div>
    </div>
  );
}
