
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { ApolloProvider } from "@apollo/client";
import { IPublicClientApplication, PublicClientApplication, } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { THEME, USER_LANG } from "@constants/global";
import "@cyntler/react-doc-viewer/dist/index.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import chartTrendline from "@nodrize/chartjs-plugin-trendline";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import "animate.css/animate.css";
import { BarElement, CategoryScale, Chart, Filler, Legend, LineElement, LinearScale, PointElement, RadialLinearScale, Title, Tooltip, } from "chart.js";
import CommandPallete from "components/CommandPallete";
import SiteHead from "components/SiteHead/SiteHead";
import SiteLoader from "components/SiteLoader/SiteLoader";
import "components/TechRadar/styles.css";
import Theme from "components/Theme/Theme";
import DrawerProvider from "context/drawer/drawer.provider";
import ModalProvider from "context/modal/modal.provider";
import { PreviousPathProvider } from "context/previousPathContext/PreviousPathContext";
import { UserStoreProvider } from "context/userStore/userStore.provider";
import { VendorProvider } from "context/vendor/vendor.provider";
import { gqlVendor } from "gql";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import es from "javascript-time-ago/locale/es-CL.json";
import { ThemeProvider } from "next-themes";
import App, { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import "prismjs/themes/prism-tomorrow.css";
import { useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Toaster } from "react-hot-toast";
import "react-leaf-polls/dist/index.css";
import { clarity } from "react-microsoft-clarity";
import "react-morphing-modal/dist/ReactMorphingModal.css";
import "react-multi-email/style.css";
import "react-nestable/dist/styles/index.css";
import "react-splitter-layout/lib/index.css";
import Routes from "routes";
import "rsuite/dist/rsuite.min.css";
import apolloClient from "settings/apollo";
import { CustomNavigationClient } from "settings/navigationClient";
import "styles/anims.scss";
import "styles/global.css";
import "styles/global.scss";
import "styles/overwrite-rsuite.scss";
import "styles/react-leaf-polls.scss";
import "styles/side-pane.scss";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import "tailwindcss/tailwind.css";
import { IVendor } from "types/Vendor.types";
import QuickieProvider from "../context/quickie/quickie.provider";
const DrawerComponent = dynamic(() => import("context/drawer/drawer.component"), {
    ssr: false
});
const isBrowser = typeof window !== "undefined";
Chart.register(RadialLinearScale, PointElement, LineElement, CategoryScale, LinearScale, Legend, BarElement, Title, Tooltip, chartTrendline, Filler);
Chart.defaults.font.family = "Montserrat, sans-serif";
Chart.defaults.font.size = 14;
// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay]);
let msalInstance: IPublicClientApplication;
interface ICustomAppProps extends AppProps {
    vendor: IVendor;
}
function Groowcity({ vendor, ...appProps }: ICustomAppProps) {
    const router = useRouter();
    const theme = isBrowser ? localStorage.getItem("theme") : "light";
    const lang = isBrowser ? localStorage.getItem(USER_LANG) : "en";
    const msalConfig = {
        auth: {
            clientId: vendor?.clientId || "00000000-0000-0000-0000-000000000000",
            authority: `https://login.microsoftonline.com/${vendor?.tenantId}`,
            redirectUri: "/login",
            postLogoutRedirectUri: "/login"
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: false
        }
    };
    msalInstance = new PublicClientApplication(msalConfig);
    useEffect(() => {
        msalInstance = new PublicClientApplication(msalConfig);
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            msalInstance.setActiveAccount(accounts[0]);
        }
        const navigationClient = new CustomNavigationClient(router);
        msalInstance.setNavigationClient(navigationClient);
        TimeAgo.addDefaultLocale(en);
        TimeAgo.addLocale(es);
        if (window.location.hostname !== "localhost") {
            clarity.init("jr136gb3nx");
        }
        // Create a new script element
        const scriptElement = document.createElement("script");
        scriptElement.type = "text/javascript";
        scriptElement.defer = true;
        // Add user information to the script content
        scriptElement.innerHTML = `
      var beamer_config = {
        product_id: 'xEMCXKOU63309',
        selector : ".beamerTrigger",
        display : "none",
        bottom: 0,
        right: 0,
        language: ${lang === "es" ? "'ES'" : "'EN'"},
        lazy: false,
        alert : true,
        button: false,
      };
    `;
        // Append the script element to the document
        document.head.appendChild(scriptElement);
        // Create another script element for the Beamer embed script
        const beamerScriptElement = document.createElement("script");
        beamerScriptElement.type = "text/javascript";
        beamerScriptElement.src = "https://app.getbeamer.com/js/beamer-embed.js";
        beamerScriptElement.defer = true;
        document.head.appendChild(beamerScriptElement);
        return () => {
            document.head.removeChild(scriptElement);
            document.head.removeChild(beamerScriptElement);
        };
    }, []);
    return (<>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <meta name="url" content={vendor?.domains?.length
            ? `https://${vendor.domains[0]}`
            : "https://app.groowcity.com"} key="portal-url"/>
        <meta property="twitter:url" content={vendor?.domains?.length
            ? `https://${vendor.domains[0]}`
            : "https://app.groowcity.com"} key="portal-twitter-url"/>
        <link href="https://app.getbeamer.com"/>
      </Head>
      <Script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyASF8EDm-_cAK1xVVvBf6qTqey_tEdi9to&libraries=places" strategy="beforeInteractive"/>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider attribute="class" defaultTheme={theme ?? undefined}>
          <Theme>
            <DrawerProvider>
              <MsalProvider instance={msalInstance}>
                <VendorProvider vendor={vendor}>
                  <UserStoreProvider>
                    <DndProvider backend={HTML5Backend}>
                      <SiteHead />
                      <PreviousPathProvider>
                        <SiteLoader>
                          <QuickieProvider>
                            <ModalProvider>
                              <DrawerComponent />
                              <Toaster position="top-center" reverseOrder={false} gutter={8} containerClassName="" containerStyle={{}} toastOptions={{
            style: theme === THEME.dark
                ? {
                    borderRadius: "10px",
                    background: "#090c11",
                    color: "#fff",
                    border: "1px solid #3c3f43"
                }
                : {
                    borderRadius: "10px",
                    background: "#fff",
                    color: "#000",
                    border: "1px solid #000"
                },
            position: "bottom-left",
            duration: 3000
        }}/>
                              <Routes {...appProps}/>
                              <CommandPallete />
                            </ModalProvider>
                          </QuickieProvider>
                        </SiteLoader>
                      </PreviousPathProvider>
                    </DndProvider>
                  </UserStoreProvider>
                </VendorProvider>
              </MsalProvider>
            </DrawerProvider>
          </Theme>
        </ThemeProvider>
      </ApolloProvider>
    </>);
}
Groowcity.getInitialProps = async (appContext) => {
    const { ctx } = appContext;
    let vendor: IVendor | null = null;
    const hostname = isBrowser ? window.location.host : ctx?.req?.headers.host;
    try {
        const { data } = await apolloClient.query<{
            vendorByHostname: IVendor;
        }>({
            query: gqlVendor.queries.VENDOR,
            fetchPolicy: "network-only",
            context: {
                headers: { hostname }
            }
        });
        vendor = data.vendorByHostname;
    }
    catch (e) {
        console.error("🫠 error.message:", e.message);
        console.error("🫠 error.hostname:", hostname);
    }
    const appProps = await App.getInitialProps(appContext);
    return { ...appProps, vendor };
};

    export default __appWithI18n(Groowcity, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  